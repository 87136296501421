<template lang='pug'>
	svg.icon-social.icon-tg(:class='classIcon' id='Bold' enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg")
		path(fill='#fff' d="m9.417 15.181-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931l3.622-16.972.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693 12.643-7.911c.595-.394 1.136-.176.691.218z")
</template>

<script>
export default {
	props: {
		color: String,
		size: String
	},

	computed : {
		classIcon () {
			let p = [];
			if ( this.color ) p.push(`icon-${this.color}`);
			if ( this.size ) p.push(`icon-${this.size}`);

			return p.join(' ');
		}
	}
}
</script>