<template lang='pug'>
	b-form.d-flex.flex-column.add-promotion(autocomplete='off')
		my-input(
			v-model.trim="form.title"
			:label="$t('promotions.form.name')"
			:errorFeedback="errors.title"
			:icon="'icon-lightning'"
			:disabled='isDisabledItem || item.subscriptionsCount && item.subscriptionsCount != 0 ? true : false'
			@blur='isTitleValid()'
		)

		span.b4.text-primary.mt-2.fontello.cursor-pointer.add-promotion__gen(
			v-if="create == PLAN_TABS.promoCode && (item.subscriptionsCount && item.subscriptionsCount != 0 ? false : true)"
			@click="form.title = genPromo(5), isTitleValid()"
		) {{ $t('h1.generate') }}

		dropdown-label.my-4(
			v-if="create == PLAN_TABS.tariff"
			:id="'period'"
			@onChange="getPeriod"
			@dropdownHide="validDropdown"
			:list="dropdownPeriods"
			:selectedId="form.period"
			:disabled='isDisabledItem || !canBeDeleted'
			:errorFeedback="errors.period"
			:icon="'icon-calendar'"
			:label="$t('forms.placeholder.paymentPeriod')"
		)

		.d-flex.position-relative.mt-4.date-picker-control.date-picker-control--promotion(v-if="create != PLAN_TABS.tariff" :class="{'is-float': form.date.start, 'my-4': form.date.start, 'blocked' : isDisabledItem || !canBeDeleted || item.subscriptionsCount && item.subscriptionsCount != 0 ? true : false}")
			v-date-picker.w-100.date-picker(v-model="form.date" :key="creationDateKey" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }" :min-date="new Date()")
				template(v-slot="{ inputValue, inputEvents, togglePopover }")
					span.icon-calendar(@click="togglePopover()")
					.d-flex.date-picker__input(@click="togglePopover()")
						input.b3.mr-1.date-picker__input-calendar.date-picker__input-calendar--first(:class="{'visually-hidden': !form.date.start && !form.date.end}" id="input-calendar" :value="inputValue.start" v-on="inputEvents.start" readonly)
						span.b3.mr-1(v-if="form.date.start && form.date.end") -
						span.b3.date-picker__input-calendar(v-if="form.date.start && form.date.end") {{ inputValue.end }}
						input.visually-hidden(:value="inputValue.end" v-on="inputEvents.end")
			label.m-0.b3.date-picker__label(:class="{'b4': form.date.start && form.date.end}" for="input-calendar") {{ create != PLAN_TABS.tariff ? $t('promotions.datePicker.date') : $t('plan.period') }}
			span.b4.date-picker__date-counter(v-if="form.date.start && form.date.end") {{ dateCounter }} {{ declOfDays(dateCounter) }}
			icon-close.date-picker__reset(v-if="form.date.start" @click="form.date.start = null, form.date.end = null, creationDateKey++")

		.d-flex(v-if="create != PLAN_TABS.tariff && form.date.start" :class='{"blocked" : isDisabledItem}')
			b-form-checkbox.b3(id="display-payment" v-model="form.isShowPeriod" name="display-payment" :value="true" :unchecked-value="false") {{ $t('promotions.form.isShowPeriod') }}

		.d-flex.flex-column.flex-md-row.my-md-4(v-if="create != PLAN_TABS.tariff")
			.d-flex.flex-column.w-100.w-md-50.mt-4.mt-md-0(:class="{'w-100 mb-4 mb-md-0': create == PLAN_TABS.stock}")
				.position-relative
					my-input(
						v-model="form.discount"
						:value="form.discount"
						:label="$t('promotions.form.discount')"
						:errorFeedback="errors.discount"
						:icon="'icon-discount'"
						:maxlength="'2'"
						:mask="'##'"
						:disabled='!canBeDeleted || isDisabledItem || item.subscriptionsCount && item.subscriptionsCount != 0 ? true : false'
						@blur='isDiscountValid()'
					)
					span.d-flex.justify-content-center.align-items-center.b6.add-promotion__tooltip-discount(v-if="!isMobile" id="tooltip-discount" :class='{"blocked" : !canBeDeleted || isDisabledItem}') ?
					span.d-flex.justify-content-center.align-items-center.b6.add-promotion__tooltip-discount(v-if="isMobile" v-b-modal.tooltipDiscount :class='{"blocked" : !canBeDeleted || isDisabledItem}') ?

			.d-flex.flex-column.w-100.w-md-50.ml-0.ml-md-2.my-4.my-md-0(v-if="create == PLAN_TABS.promoCode")
				my-input(
					v-model="form.usage.total"
					:mask="'######'"
					:label="$t('promotions.form.usage')"
					:errorFeedback="errors.capacity"
					:icon="'icon-usage'"
					:disabled='isDisabledItem || item.subscriptionsCount && item.subscriptionsCount != 0 ? true : false'
					@blur='isCapacityValid()'
				)

		my-input(
			v-if="create == PLAN_TABS.tariff"
			v-model="form.price"
			:label="$t('promotions.form.price')"
			:errorFeedback="errors.price"
			:icon="'icon-money'"
			:disabled='isDisabledPCode || isDisabledPrice || isDisabledItem || !canBeDeleted'
			:mask="'######'"
			@blur='isPriceValid()'
		)

		dropdown-label.mt-4(
			v-if="create == PLAN_TABS.tariff"
			:id="'currency'"
			@onChange="getCurrency"
			@dropdownHide="validDropdown"
			:list="dropdownCurrency"
			:selectedId="form.currency"
			:disabled='isDisabledPCode || isDisabledPrice || isDisabledItem || !canBeDeleted'
			:errorFeedback="errors.currency"
			:icon="'icon-buy-crypto'"
			:label="$t('forms.placeholder.currency')"
			)

		dropdown-label.my-4(
			v-if="create == PLAN_TABS.tariff"
			@onChange="getFreePeriod"
			:list="dropdownFreePeriods"
			:selectedId="form.freePeriod"
			:icon="'icon-gift'"
			:disabled='isDisabledItem'
			:label="$t('forms.placeholder.free')"
		)

		div(v-if="resources.length > 0")
			.d-flex.flex-column(v-for="(channel, i) in resources" :key="`channel-${i}`" :class='{"blocked" : isDisabledItem}')
				b-form-checkbox.my-2.add-promotion__channel(ref="addPromotionChannel" v-model="form.selectedChannels" :id="`channel-${i}`" :value="channel.id" :class="{'active-plan': form.selectedChannels.includes(resources[i].id)}")
					.d-flex.justify-content-center.align-items-center.add-promotion__avatars(:class="{'brand-gradient': !channel.image || !channel.image.contentUrl}")
						img(v-if="channel.image && channel.image.contentUrl" :src="channel.image.contentUrl")
						span(v-else) {{ `${channel.title}`.slice(0,1) }}
					.d-flex.flex-column.align-items-start
						span.b3.ml-3 {{ channel.title }}
						b-badge.b3.ml-3.add-promotion__bot-disconnected(v-if="channel.isBotRemoved() || channel.hasBotIssue()" variant="danger" pill v-html="$t('bot.disconnected')")
			.text-danger.b4(v-if="errorResource" v-html="$t('errors.required.resource')")

		p.b3.my-4.disclaimer.error(v-else-if='create == PLAN_TABS.tariff')
			span.b3 {{ $t('promotions.tariff.withoutResourcesDesc2') }}
			span.btn.btn-subtle.add-promotion__disclaimer-btn(@click="goToResources") {{ $t('button.goTo') }}

		div(v-if="plans.length > 0")
			span.d-flex.b1 {{ $t('promotions.plans') }}
			.d-flex.flex-column(v-for="(plan, i) in plans" :key="`plan-${i}`" :class='{"blocked" : isDisabledItem}')
				b-form-checkbox.my-2.add-promotion__plan(ref="addPromotionPlan" v-model="form.selectedPlans" :id="`plan-${i}`" :value="plan.id" :class="{'active-plan': form.selectedPlans.includes(plans[i].id), 'error-plan': !isPlanValid(plan) && form.selectedPlans.includes(plans[i].id)}" @change="errorPricePlan")
					span.b3.mr-1.overflow-wrap(v-html="plan.title")
					.d-flex.align-items-end
						span.b3.mr-2.add-promotion__price--old.text-nowrap(v-if="form.selectedPlans.includes(plans[i].id)" v-html="`${plan.price.priceWithCurrency()}`")
						span.b1.add-promotion__price.text-nowrap(v-if="form.selectedPlans.includes(plans[i].id)" v-html="`${planPriceWithDiscount(plan)}&nbsp;${plan.price.currencyTitle()}`")
						span.b1.add-promotion__price.text-nowrap(v-if="!form.selectedPlans.includes(plans[i].id)" v-html="`${plan.price.priceWithCurrency()}`")
				.text-danger.b4(v-if="!isPlanValid(plan) && form.selectedPlans.includes(plan.id)" v-html='planError(plan)')
			.text-danger.b4(v-if="errorRate" v-html="$t('errors.required.rate')")

		div.b3.my-4.disclaimer.error(v-else-if='create == PLAN_TABS.stock')
			span.b3 {{ $t('promotions.stock.noPlansDesc') }}
			span.btn.btn-subtle.add-promotion__disclaimer-btn(@click="goToPlans") {{ $t('button.goTo') }}

		div.b3.my-4.disclaimer.error(v-else-if='create == PLAN_TABS.promoCode')
			span.b3 {{ $t('promotions.promoCodes.noPlansDesc') }}
			span.btn.btn-subtle.add-promotion__disclaimer-btn(@click="goToPlans") {{ $t('button.goTo') }}

		.d-flex.flex-grow-1.justify-content-end.mt-4
			span.btn.btn-primary.w-100.mt-auto(v-if='!isDisabledItem' @click="createPromotion" :class="{'disabled': isDisabled || isloading}")
				.loader(v-if="isloading")
				span(v-if='form.id && !isloading') {{ $t('forms.save') }}
				span(v-else-if="!isloading") {{ $t('promotions.form.btn') }}
			span.btn.btn-primary.w-100.mt-auto(v-else @click="unarchive" :class="{'disabled': isDisabled || isloading}")
				.loader(v-if="isloading")
				span(v-else-if='create == PLAN_TABS.tariff') {{ $t('project.unarchiveProject') }}
				span(v-else-if='create == PLAN_TABS.stock') {{ $t('project.unarchivePromotion') }}
				span(v-else-if='create == PLAN_TABS.promoCode') {{ $t('project.unarchivePromocode') }}

		b-tooltip(v-if="create != PLAN_TABS.tariff" target="tooltip-discount" placement="bottom" no-fade triggers="hover")
			p.b3.mb-3 {{ $t('promotions.form.tooltipDiscount') }}
			pw-btn-link(:text="$t('promotions.promotionsInfo.plans[0]')" :href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#limity-na-stoimost'" :icon="'icon-document'" :blank='true')

		b-modal#tooltipDiscount(content-class="add-promotion__modal-discount" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3 {{ $t('promotions.attention') }}
				icon-close(@click="close()")
			p.b3.mb-3 {{ $t('promotions.form.tooltipDiscount') }}
			pw-btn-link.mb-4(:text="$t('promotions.promotionsInfo.plans[0]')" :href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#limity-na-stoimost'" :blank='true' :icon="'icon-document'")
			span.btn.btn-primary(@click="$bvModal.hide('tooltipDiscount')") {{ $t('promotions.btnFine') }}
</template>

<script>
import DropdownLabel from '@/components/Dropdown/DropdownLabel';
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import IconClose from "@/components/Common/IconClose";
import MyInput from "@/components/UI/MyInput";
import { Plan, PERIODS, PERIODSFREE } from '@/models/plan';
import { PLAN_TABS } from '@/models/project';
import { CURRENCY_EMOJI, Money } from '@/models/money';
import { STATUS_ACTIVE } from '@/models/model';
import { STORAGE } from '@/api/storage';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';

export default {
	name: 'AddPromotion',
	components: {
		DropdownLabel,
		PwBtnLink,
		IconClose,
		MyInput
	},
	props: {
		create: {
			default: '',
			type: String
		},
		modalId: {
			default: '',
			type: String
		},
		plans: {
			default: () => [],
			type: Array
		},
		channels: {
			default: () => [],
			type: Array
		},
		item: {
			default: () => ({}),
			type: Object
		}
	},
	data: () => ({
		creationDateKey: 0,
		PLAN_TABS: PLAN_TABS,
		resources: [],
		dateCounter: null,
		currentLanguage: STORAGE.getLang(),
		isDisabled: true,
		isDisabledPrice: false,
		isDisabledPCode: false,
		masks: {
			input: 'DD.MM.YYYY',
			weekdays: 'WW'
		},
		dropdownPeriods: [],
		dropdownCurrency: [],
		dropdownFreePeriods: [],
		form : {
			title: null,
			date: {
				start: null,
				end: null,
			},
			isShowPeriod: false,
			discount: null,
			selectedPlans: [],
			usage: {
				total: null
			},
			period: null,
			price: null,
			currency: null,
			freePeriod: null,
			selectedChannels: [],
		},
		errors: {
			title: '',
			discount: '',
			price: '',
			capacity: '',
			period: '',
			currency: ''
		},
		isloading: false,
		errorResource: false,
		errorRate: false
	}),
	computed: {
		...mapGetters({
			projectChannels: 'resources/channels',
			projectGroups: 'resources/groups',
			project: 'project/opened',
			promotions: 'project/promotions',
			promocodes: 'project/promocodes',
			canBeDeletedPlans: 'project/canBeDeletedPlans'
		}),
		getDateCounter() {
			if ( this.create == PLAN_TABS.tariff ) return null;

			const oneDay = 1000 * 60 * 60 * 24;
			const diffInTime = moment(this.form.date.end).diff(this.form.date.start);
			const diffInDays = Math.round(diffInTime / oneDay + 1);
			return diffInDays;
		},
		isDisabledItem() {
			return this.item && this.item.isArchived && this.item.isArchived();
		},
		canBeDeleted() {
			if (this.canBeDeletedPlans.filter(el => el.id == this.item.id )[0] && this.canBeDeletedPlans.filter(el => el.id == this.item.id )[0].isCanBeDeleted === false)
				return false;

			return true;
		},
	},
	created() {
		this.getDropdownPeriods();
		this.getDropdownCurrency();
		this.getDropdownFreePeriods();

		if(this.create == PLAN_TABS.stock) {
			this.setDefaultStock(this.item);
			this.dateCounter = this.getDateCounter;
			this.validationStock(this.form);
		} else if (this.create == PLAN_TABS.promoCode) {
			this.setDefaultPromoCode(this.item);
			this.dateCounter = this.getDateCounter;
			this.validationPromoCode(this.form);
		} else if (this.create == PLAN_TABS.tariff) {
			this.setDefaultData(this.item);
			this.validationTariff(this.form);
		}
	},
	mounted() {
		if (this.modalId == `edit-tariff-${this.item.id}`)
			this.setResources();
	},
	methods: {
		...mapActions({
			addPlan: 'plans/add',
			editPlan: 'plans/edit',
			removePlan: 'plans/remove',
			putChannels: 'plans/putChannels',
			putGroups: 'plans/putGroups',

			addPromotion: 'project/addPromotion',
			editPromotion: 'project/editPromotion',

			addPromocode: 'project/addPromocode',
			editPromocode: 'project/editPromocode',

			aPlan: 'plans/activate',
			aPromotion: 'project/activatePromotion',
			aPromocode: 'project/activatePromocode',
		}),
		goToResources () { this.$router.push({name: 'project_resources'}) },
		goToPlans () {
			this.$emit('goToPlans', true);
			this.$bvModal.hide('promo-code-form');
			this.$bvModal.hide('stock-form');
		},
		getPeriod(data) {
			this.form.period = data;
		},
		getCurrency(data) {
			this.form.currency = data;
			if ( this.form.price ) this.isPriceValid();
		},
		getFreePeriod(data) {
			this.form.freePeriod = data;
		},
		unarchive() {
			this.isloading = true;

			if (this.create == PLAN_TABS.stock) {
				return this.editPromotion({id: this.form.id, status: {value: STATUS_ACTIVE}}).then(v => {
					this.item.setArchive(false);
					this.$notify("success", this.$t('success.promotionActive'));
					this.isloading = false;
				})
				.catch( (v) => {
					this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
					this.isloading = false;
				})
			}
			if (this.create == PLAN_TABS.promoCode) {
				return this.editPromocode({id: this.form.id, status: {value:STATUS_ACTIVE}}).then(v => {
					this.item.setArchive(false);
					this.$notify("success", this.$t('success.promocodeActive'));
					this.isloading = false;
				}).catch( (v) => {
					this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
					this.isloading = false;
                });
			}
			if (this.create == PLAN_TABS.tariff) {
				return this.aPlan({id: this.form.id}).then(v => {
					this.item.setArchive(false);
					this.$notify("success", this.$t('success.planActive'));
					this.isloading = false;
				})
				.catch( (v) => {
					this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
					this.isloading = false;
				})
			}
		},
		async createPromotion() {
			this.isloading = true;
			if(this.create == PLAN_TABS.stock) {
				if ( this.form.id ) return this.editStock();

				this.addStock();
			} else if(this.create == PLAN_TABS.promoCode) {
				if ( this.form.id ) return this.editCode();

				this.addCode();
			} else if(this.create == PLAN_TABS.tariff) {
				if ( this.form.id ) return this.editTariff();

				this.addTariff();
			}
		},
		getDropdownPeriods() {
			this.dropdownPeriods 	= _.map(_.filter(PERIODS, pp => pp.active), p => ({id: p.id, title: this.$t(`plan.periodsSelect.${p.title}`)}));
		},
		getDropdownCurrency() {
			this.dropdownCurrency = _.map(CURRENCY_EMOJI, (k,i) => { return {id: i, title: `${i} ${k}`} })
		},
		getDropdownFreePeriods() {
			this.dropdownFreePeriods 		= _.map(PERIODSFREE, p => ({id: p.id, title: this.$t(`plan.freePeriods.${p.title}`)}));
		},
		// Функция правильного склонения слов
		declOfNum(n, text_arr) {
			let num = Math.abs(n) % 100;
			let n1 = num % 10;

			if (num > 10 && num < 20) {
				return text_arr[2];
			}
			if (n1 > 1 && n1 < 5) {
				return text_arr[1];
			}
			if (n1 == 1) {
				return text_arr[0];
			}
			return text_arr[2];
		},
		declOfDays(num) {
			return this.declOfNum(num, [this.$t('promotions.declOfDays[0]'), this.$t('promotions.declOfDays[1]'), this.$t('promotions.declOfDays[2]')]);
		},
		errorPricePlan() {
			this.$refs.addPromotionPlan.forEach(item => {
				if (item.$el.classList.contains('error-plan')) {
					this.isDisabled = true;
				}
			});
		},
		isTitleValid () {
			let valid = true;
			let v = this.form.title;
			this.errors.title = '';

			if ( this.create == PLAN_TABS.tariff ) {
				if ( _.find(this.project.plans, p => p.title == v && this.item.title != v)) {
					this.errors.title = this.$t('errors.planAlreadyExist');
					return false;
				}
			}

			if ( this.create == PLAN_TABS.tariff ) {
				if ( v == null || v.length == 0 ) {
					this.errors.title = this.$t('errors.required.title');
					return false;
				}

				if ( v.length < 3 ) {
					valid = false;
					this.errors.title = this.$t('errors.minLength.title' );
				}

				if ( v.length > 50 ) {
					valid = false;
					this.errors.title = this.$t('errors.maxLength.title' );
				}
			}

			if ( this.create == PLAN_TABS.stock ) {
				if ( v == null || v.length == 0 ) {
					this.errors.title = this.$t('errors.required.title');
					return false;
				}

				if ( v.length < 3 ) {
					valid = false;
					this.errors.title = this.$t('errors.lengthStock' );
				}

				if ( v.length > 50 ) {
					valid = false;
					this.errors.title = this.$t('errors.lengthStock' );
				}
			}

			if ( this.create == PLAN_TABS.stock ) {
				const regex = /^[a-zа-яёË0-9\s.,?!:-–—-]+$/iu;

				if ( v.indexOf('@') != -1 ) {
					valid = false;
					this.errors.title = this.$t('errors.alpha.dots' );
				}

				if ( !regex.test(v) && v.indexOf('@') != -1 ) {
					valid = false;
					this.errors.title = this.$t('errors.alpha.dots' );
				}
			}

			if ( this.create == PLAN_TABS.promoCode ) {
				const regex = /^[a-z0-9]+$/iu;
				if ( !regex.test(v) ) {
					valid = false;
					this.errors.title = this.$t('errors.alpha.enNumbers' );
				}

				if ( v.length == 0 ) {
					valid = false;
					this.errors.title = this.$t('promotions.form.errorTitle' );
				}

				if ( v.length < 4 && v.length != 0 ) {
					valid = false;
					this.errors.title = this.$t('errors.lengthPromo' );
				}

				if ( v.length > 6 ) {
					valid = false;
					this.errors.title = this.$t('errors.lengthPromo' );
				}
			}

			return valid;
		},

		isDiscountValid () {
			let valid = true;
			let v = this.form.discount;
			this.errors.discount = '';

			if ( v == null || v.length == 0 || v == 0 ) {
				valid = false;
				this.errors.discount = this.$t('promotions.form.error');
			}

			if ( v != null && (Number(v) < 10 || Number(v) > 90) ) {
				valid = false;
				this.errors.discount = this.$t('promotions.form.errorDiscountRange');
			}

			return valid;
		},

		isCapacityValid () {
			let valid = true;
			this.errors.capacity = '';

			if ( this.form.usage.total % 1 != 0 ) {
				this.errors.capacity =  this.$t('errors.numeric.capacity');
			}

			if ( this.form.usage.total && this.form.usage.total < 1 ) {
				valid = false;
				this.errors.capacity = this.$t('errors.numeric.1');
			}

			return valid;
		},

		isPriceValid () {
			let valid = true;
			let v = this.form.price;
			let currency = _.isObject(this.form.currency) && this.form.currency.title ? this.form.currency.title : this.form.currency;
			this.errors.price = '';

			if ( v == null || v.length == 0 || v == 0 ) {
				valid = false;
				this.errors.price = this.$t('forms.placeholder.price');
				return;
			}

			if ( v % 1 != 0 ) {
				this.errors.price =  this.$t('errors.numeric.price');
				return;
			}

			if ( isNaN(Number(v)) ) {
				valid = false;
				this.errors.price =  this.$t('errors.numeric.price');
				return;
			}

			if ( Money.isCurrencyRUB(currency) ) {
				let min = v >= 99;
				let max = v <= 100000;

				if ( !(min && max) ) {
					valid = false;
					this.errors.price =  this.$t('plan.priceRangeRUB');
				}
			}

			if ( Money.isCurrencyUSD(currency) || Money.isCurrencyEUR(currency) ) {
				let min = v >= 5;
				let max = v <= 500;

				if ( !(min && max) ) {
					valid = false;
					this.errors.price = Money.isCurrencyUSD(currency) ? this.$t('plan.priceRangeUSD') : this.$t('plan.priceRangeEUR');
				}
			}

			return valid;
		},
		isPlanValid (plan) {
			return this.planPriceWithDiscount(plan) >= Plan.getMinPrice(plan);
		},
		planPriceWithDiscount ( plan ) {
			if (plan.price.isCurrencyRUB()) {
				return Math.round(plan.price.price() / 100 * (100 - this.form.discount));
			} else {
				return (plan.price.price() / 100 * (100 - this.form.discount)).toFixed(2).replace('.00', '');
			}
		},
		planError ( plan ) {
			return this.$t('promotions.form.errorPrice').replace('%value%', Plan.getMinPrice(plan) + plan.price.currencyTitle())
		},
		validationStock(val) {
			this.isDisabled = !(val.selectedPlans.length != 0 &&
				this.errors.title.length == 0 && val.title != null && val.title != '' &&
				this.errors.discount.length == 0 && val.discount != null && val.discount != '');
		},
		validationPromoCode(val) {
			this.isDisabled = !(val.selectedPlans.length != 0 &&
				this.errors.title.length == 0 && val.title != null && val.title != '' &&
				this.errors.discount.length == 0 && val.discount != null && val.discount != '' &&
				val.usage.total != '0');
		},
		validationTariff(val) {
			if (val.selectedChannels && val.selectedChannels.length != 0 &&
				this.errors.title.length == 0 && val.title != null && val.title != '' &&
				this.errors.price.length == 0 && val.price != null && val.price != '' &&
				val.currency != null && val.currency != '' &&
				val.period != null && val.period != ''
			) return this.isDisabled = false;

			this.isDisabled = true;
		},

		setDefaultStock ( val ) {
			_.each(this.errors, (v, title) => this.errors[title] = '');

			if ( val && val != null && val.id ) {
				this.form.title 		= val.title;
				this.form.isShowPeriod 	= val.isShowPeriod;
				this.form.date = {
					start 	: val.startAt ? val.startAt.split('T')[0] : null,
					end 	: val.endAt ? val.endAt.split('T')[0] : null
				};
				this.form.discount 		= val.discount;
				this.form.selectedPlans	= _.map(val.plans, p => p.id);
				this.form.id 			= val.id;
				return;
			}

			delete this.form.id;
		},

		setDefaultPromoCode ( val ) {
			_.each(this.errors, (v, title) => this.errors[title] = '');

			if ( val && val != null && val.id ) {
				this.form.title 		= val.title;
				this.form.isShowPeriod 	= val.isShowPeriod;
				this.form.date = {
					start 	: val.startAt ? val.startAt.split('T')[0] : null,
					end 	: val.endAt ? val.endAt.split('T')[0] : null
				};
				this.form.discount 		= val.discount;
				if (val.capacity) {
					this.form.usage.total	= val.capacity + val.subscriptionsCount !=0 ? val.capacity + val.subscriptionsCount : null;
				} else {
					this.form.usage.total	= null;
				}
				this.form.selectedPlans	= _.map(val.plans, p => p.id);
				this.form.id 			= val.id;
				return;
			}

			delete this.form.id;
		},

		setDefaultData ( val ) {
			_.each(this.errors, (v, title) => this.errors[title] = '');

			if ( val && val != null && val.id ) {
				this.form.title 	= val.title;
				this.form.period	= val.period.id;
				this.form.freePeriod= val.free.id;
				this.form.price		= val.price.amount;
				this.form.currency  = val.price.currency;
				this.form.resources	= val.getResources();
				this.form.id 		= val.id;
				this.isDisabledPrice= _.find(this.promotions, p => _.some(p.plans, pl => pl.id == val.id)) ? true : false;
				this.isDisabledPCode= _.find(this.promocodes, p => _.some(p.plans, pl => pl.id == val.id)) ? true : false;
				//this.setResources();
				return;
			}

			this.form.title 	= null;
			this.form.period	= null;
			this.form.trial		= null;
			this.form.price		= null;
			this.form.currency  = null;
			this.form.resources	= [];
			this.resources = _.filter([...this.projectChannels, ...this.projectGroups], v => v.isActive());
			//this.setResources();
			delete this.form.id;
		},
		setResources () {
			this.resources = [];
			this.form.selectedChannels = [];

			_.each(_.filter(this.projectChannels, v => v), rA => {
				let r = _.cloneDeep(rA);

				_.each(this.form.resources, fr => {
					if ( r.id == fr.id ) r.checked = true;
				})

				this.resources.push(r);
			});

			_.each(_.filter(this.projectGroups, v => v), rA => {
				let r = _.cloneDeep(rA);

				_.each(this.form.resources, fr => {
					if ( r.id == fr.id ) r.checked = true;
				})

				this.resources.push(r);
			});

			this.form.selectedChannels = _.map(_.filter(this.resources, r => r.checked), rr => rr.id);
		},

		addCode () {
			this.addPromocode(this.prepareCodeDate())
				.then((i) => {
					this.project.addPromocode(i);
					this.$emit('addPromocode');
					setTimeout(() => {
						this.$notify("success", this.$t('success.promocodeCreated'));
						this.hide();
					}, 3500);
				})
				.catch( v => {
					this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
					this.isloading = false;
				})
		},

		editCode () {
			let data = this.prepareCodeDate();
			data.id = this.form.id;

			this.editPromocode(data)
				.then((i) => {
					this.project.editPromocode(i);
					this.$emit('editPromocode');
					setTimeout(() => {
						this.$notify("success", this.$t('success.promocodeEdited'));
						this.hide();
					}, 3500);
				})
				.catch( v => {
					this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
					this.isloading = false;
				})
		},


		addStock () {
			this.addPromotion(this.prepareStockDate())
				.then(async (i) => {
					this.$notify("success", this.$t('success.promotionCreated'));
					this.project.addPromotion(i);
					this.hide();
				})
				.catch( v => {
					this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
					this.isloading = false;
				})
		},

		editStock () {
			let data = this.prepareStockDate();
			data.id = this.form.id;

			this.editPromotion(data)
				.then((i) => {
					i.canBeDeleted = this.item.canBeDeleted;
					this.$notify("success", this.$t('success.promotionEdited'));
					this.hide();
					this.project.editPromotion(i);
				})
				.catch( v => {
					this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
					this.isloading = false;
				})
		},

		editTariff () {
			let data = this.prepareTariffData();
			data.id = this.form.id;

			this.editPlan(data).then( async i => {
				await this.saveChannels();
				await this.saveGroups();

				this.$notify("success", this.$t('success.planEdited'));

				i.setChannels(this.getSelectedChannels());
				i.setGroups(this.getSelectedGroups());
				i.setCanBeDeleted(this.item.canBeDeleted);

				this.$store.commit('project/editPlan', i);
				this.$store.commit('plans/edit', i);

				this.project.isReady();
				this.hide();
			})
			.catch( (v) => {
				this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
				this.isloading = false;
			})
		},

		addTariff () {
			let data = this.prepareTariffData();

			this.addPlan(data)
				.then(async (i) => {
					this.form.id = i.id;
					this.$notify("success", this.$t('success.planCreated'));
					this.$store.commit('plans/putChannels', {id: this.form.id, items: this.getSelectedChannels()});
					this.$store.commit('plans/putGroups', {id: this.form.id, items: this.getSelectedGroups()});

					this.saveChannels();
					this.saveGroups();

					this.$store.commit('project/addPlan', {plan: i, active: this.me.hasFilledAccount() && this.me.hasFilledPaymentAccounts()});

					this.hide();
				})
				.catch( v => {
					this.$notify("error", '😢 &nbsp;&nbsp;&nbsp;' + v);
					this.isloading = false;
				})
		},

		prepareStockDate () {
			let out = {
				title: this.form.title,
				isShowPeriod: this.form.isShowPeriod,
				discount: Number(this.form.discount),
				plans: this.form.selectedPlans,
				startAt: this.form.date.start ? moment(this.form.date.start).format('YYYY-MM-DDT00:00:00') : null,
				endAt: this.form.date.end ? moment(this.form.date.end).format('YYYY-MM-DDT23:59:59') : null
			};

			return out;
		},

		prepareCodeDate () {
			let subscriptionsCount = this.item.subscriptionsCount ? this.item.subscriptionsCount : 0;

			let out = {
				code: this.form.title,
				isShowPeriod: this.form.isShowPeriod,
				discount: Number(this.form.discount),
				plans: this.form.selectedPlans,
				startAt: this.form.date.start ? moment(this.form.date.start).format('YYYY-MM-DDT00:00:00') : null,
				endAt: this.form.date.end ? moment(this.form.date.end).format('YYYY-MM-DDT23:59:59') : null,
				capacity: this.form.usage.total ? Number(this.form.usage.total - subscriptionsCount) : null
			};

			return out;
		},

		prepareTariffData () {
			let currency = _.isObject(this.form.currency) ? this.form.currency.title : this.form.currency;

			let data = {
				title			: this.form.title,
				price           : {
					amount		: String(Number(this.form.price)*100),
					currency	: {code: currency },
				},
				paymentInterval	: this.form.period == _.last(PERIODS).id ? null : this.form.period,
				trialInterval	: this.form.freePeriod == _.first(PERIODSFREE).id ? null : this.form.freePeriod,
				project			: this.project.id,
			};

			return data;
		},

		async saveChannels () {
			return new Promise( async (res, rej) => {
				this.putChannels({id: this.form.id, channels: _.map(this.getSelectedChannels(), r => r.id ) })
					.then( i => res ? res() : null )
					.catch( i => rej ? rej() : null )
			})
		},

		async saveGroups () {
			return new Promise( async (res, rej) => {
				this.putGroups({id: this.form.id, groups: _.map(this.getSelectedGroups(), r => r.id )})
					.then( i => res ? res() : null )
					.catch( i => rej ? rej() : null )
			})
		},

		getSelectedChannels () {
			return _.filter(this.projectChannels, fr => _.some(this.form.selectedChannels, ch => ch == fr.id));
		},

		getSelectedGroups () {
			return _.filter(this.projectGroups, fr => _.some(this.form.selectedChannels, ch => ch == fr.id));
		},

		hide() {
			this.$bvModal.hide(this.modalId);
			this.isloading = false;
		},
		genPromo(len) {
			const chrs = 'ABDEFGHKMNPQRSTWXZ';
			let str = '';

			for (let i = 0; i < len; i++) {
				let pos = Math.floor(Math.random() * chrs.length);
				str += chrs.substring(pos, pos + 1);
			}
			return str.toUpperCase();
		},
		validDropdown(data) {
			if (data =='period') {
				if (!this.form.period) {
					this.errors.period = this.$t('errors.required.period')
				} else {
					this.errors.period = '';
				}
			} else if (data =='currency') {
				if (!this.form.currency) {
					this.errors.currency = this.$t('errors.required.currency')
				} else {
					this.errors.currency = '';
				}
			}
		}
	},
	watch: {
		item: {
			handler(val) {
				if (this.create == PLAN_TABS.stock) return this.setDefaultStock(val);
				if (this.create == PLAN_TABS.tariff) return this.setDefaultData(val);
				if (this.create == PLAN_TABS.promoCode) return this.setDefaultPromoCode(val);
			},
			deep: true
		},
		'form.discount'() {
			setTimeout(() => {
				this.errorPricePlan();
			}, 30);
		},
		form: {
			handler(val, oldVal) {
				if (this.create == PLAN_TABS.stock) {
					this.validationStock(val);
				} else if (this.create == PLAN_TABS.promoCode) {
					this.validationPromoCode(val);
				} else if (this.create == PLAN_TABS.tariff) {
					this.validationTariff(val);
				}

				this.dateCounter = this.getDateCounter;
			},
			deep: true
		},
		errors: {
			handler(val, oldVal) {
				if (this.create == PLAN_TABS.stock) {
					this.validationStock(this.form);
				} else if (this.create == PLAN_TABS.promoCode) {
					this.validationPromoCode(this.form);
				} else if (this.create == PLAN_TABS.tariff) {
					this.validationTariff(this.form);
				}

				this.dateCounter = this.getDateCounter;

			},
			deep: true
		},
		'form.selectedChannels': {
			handler(nV, oV) {
				if (nV.length == 0 && oV.length == 0) {
					this.errorResource = false;
				} else if (nV.length != oV.length && this.form.selectedChannels == 0) {
					this.errorResource = true;
				} else if (this.form.selectedChannels > 0) {
					this.errorResource = false;
				}
			},
			deep: true
		},
		'form.selectedPlans': {
			handler(nV, oV) {
				if (nV.length == 0 && oV.length == 0) {
					this.errorRate = false;
				} else if (nV.length != oV.length && this.form.selectedPlans == 0) {
					this.errorRate = true;
				} else if (this.form.selectedPlans > 0) {
					this.errorRate = false;
				}
			},
			deep: true
		}
	}
}
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.add-promotion {
	@include respond-below(sm) {
		flex-grow: 1;
	}
	.blocked {
		.date-picker__date-counter {
			background-color: var(--grey-bg-color);
		}
		.date-picker__input {
			background-color: var(--foreground-color);
			border-color: var(--grayscale-dark-heavy);
		}
	}

	&__gen {
		width: max-content;
	}

	&__tooltip-discount {
		z-index: 1;
		position: absolute;
		top: 13px;
		right: 22px;
		width: 27px;
		height: 27px;
		color: var(--primary-text-color);
		background-color: var(--foreground-color);
		border-radius: 50%;
		cursor: pointer;

		&.blocked {
			background-color: var(--foreground-color);
		}

		@include respond-below(sm) {
			right: 16px;
		}
	}

	&__text-discount {
		position: absolute;
		bottom: 6px;
		left: 70px;
	}

	&__plan {
		padding: 10px 18px 10px 40px;
		background-color: var(--grayscale-bg-dark-back);
		border-radius: var(--border-radius-rounded);
		transition: var(--animation-time-short);

		&.active-plan {
			background-color: var(--primary-bg-color);
		}

		.custom-control-label {
			padding-top: 0;
			justify-content: space-between;

			&::before,
			&::after {
				top: 50% !important;
				transform: translateY(-50%) !important;
			}

			&::before {
				background-color: var(--grayscale-dark-light-back) !important;
			}
		}

		.custom-control-input:checked ~ .custom-control-label {
			&::before {
				background-color: var(--brand-primary) !important;
			}
		}

		&.error-plan {
			background-color: var(--red-light-bg-color) !important;

			.custom-control-input:checked ~ .custom-control-label {
				&::before {
					background-color: var(--red-text-color) !important;
					border-color: var(--red-text-color) !important;
				}
			}
		}
	}

	&__price {
		&--old {
			color: var(--grey-text-color);
			text-decoration: line-through;
		}
	}

	&__channel {
		padding: 10px 18px 10px 40px;
		background-color: var(--grayscale-bg-dark-back);
		border-radius: var(--border-radius-rounded);
		transition: var(--animation-time-short);

		&.active-plan {
			background-color: var(--primary-bg-color);

			.brand-gradient {
				background: #59CB94;
			}
		}

		.custom-control-label {
			padding-top: 0;

			&::before,
			&::after {
				top: 50% !important;
				transform: translateY(-50%) !important;
			}

			&::before {
				background-color: var(--background-color);
			}
		}
	}

	&__avatars {
		width: 41px;
		min-width: 41px;
		height: 41px;
		min-height: 41px;
		font-size: 17px;
		line-height: 14px;
		text-transform: uppercase;
		color: var(--foreground-color);
		border-radius: 50%;

		img {
			display: block;
			border-radius: 50%;
			width: 100%;
			height: 100%;
		}

		&.brand-gradient {
			background: var(--brand-gradient-primary, linear-gradient(227deg, #0071F4 0%, #66B4FF 100%));
		}
	}

	&__modal-discount {
		padding: 16px 20px 28px;
	}

	&__disclaimer-btn {
		height: 38px;
		max-width: 170px;
		margin-top: 10px;
	}

	&__bot-disconnected {
		padding: 1px 6.5px 2px !important;
		margin-top: 5px;
	}
}
</style>
